@keyframes fadeMoveToLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.hide-market-to-left {
  animation: fadeMoveToLeft 1s ease-in-out forwards;
}

@keyframes movePortalToCenter {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(calc(50vw - 1218px));
  }
}

.move-portal-to-center {
  animation: movePortalToCenter 1s ease-in-out forwards;
}

.portal-center {
  transform: translateX(0);
}
