@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Metropolis';
    src: url(./assets/fonts/Metropolis-Regular.otf)
      format('opentype');
    font-weight: 400;
  }
  @font-face {
    font-family: 'Metropolis';
    src: url(./assets/fonts/Metropolis-Medium.otf)
      format('opentype');
    font-weight: 500;
  }
  @font-face {
    font-family: 'Metropolis';
    src: url(./assets/fonts/Metropolis-Bold.otf)
      format('opentype');
    font-weight: 700;
  }
  @font-face {
    font-family: 'Metropolis';
    src: url(./assets/fonts/Metropolis-Black.otf)
      format('opentype');
    font-weight: 800;
  }
  @font-face {
    font-family: 'Audiowide';
    src: url(./assets/fonts/Audiowide-Regular.ttf)
      format('truetype');
    font-weight: 400;
  }
  @font-face {
    font-family: 'Familjen Grotesk';
    font-style: normal;
    font-weight: 400;
    src: url(./assets/fonts/FamiljenGrotesk-Regular.ttf) format('opentype');
  }
  @font-face {
    font-family: 'Familjen Grotesk';
    font-style: normal;
    font-weight: 500;
    src: url(./assets/fonts/FamiljenGrotesk-Medium.ttf) format('opentype');
  }
  @font-face {
    font-family: 'Familjen Grotesk';
    font-style: normal;
    font-weight: 600;
    src: url(./assets/fonts/FamiljenGrotesk-SemiBold.ttf) format('opentype');
  }
  @font-face {
    font-family: 'Familjen Grotesk';
    font-style: normal;
    font-weight: 700;
    src: url(./assets/fonts/FamiljenGrotesk-Bold.ttf) format('opentype');
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  @apply text-sm;
  @apply font-normal;
}

strong {
  @apply text-warning-dark;
}

input::-input-placeholder {
  color: rgba(0,0,0,0.9);
}
input::-webkit-input-placeholder {
  color: rgba(0,0,0,0.9);
}
input::-moz-input-placeholder {
  color: rgba(0,0,0,0.9);
}
input::-ms-input-placeholder {
  color: rgba(0,0,0,0.9);
}

#root {
  @apply font-body;
  display: flex;
  flex-direction: column;
}

#root,
body,
html {
  height: 100%;
  overflow: hidden;
}

/* keep light/dark color theme for future reference */
.light {
  --color-primary-10: #f0fcfa;
  --color-primary-90: #1e2a28;
  --color-error-10: #fdf2f1;
  --color-error-90: #2b2021;
  --color-success-10: #edf9ec;
  --color-success-90: #252e25;
  --color-warning-10: #fff7ed;
  --color-warning-90: #2b2820;
  --color-info-10: #f4f7ff;
  --color-info-90: #0d1032;
  --color-gray-0: #fff;
  --color-gray-10: #f7f8fa;
  --color-gray-20: #eaecef;
  --color-gray-40: #a9abb2;
  --color-gray-60: #424242;
  --color-gray-80: #111111;
  --color-gray-90: #2a2c33;
  --color-gray-100: #1b1b1c;
  --shadow-common: 2px 8px 44px #e3edff;

  --color-A-700: #A22982;

  --color-G-100: #F3F4F6;
  --color-G-300: #D1D5DB;
  --color-G-200: #E5E7EB;
  --color-G-400: #9CA3AF;
  --color-G-500: #6B7280;
  --color-G-600: #4B5563;
  --color-G-900: #111827;
  --color-S-100: #F4ECFB;
  --color-S-600: #9950CD;
  --color-S-700: #843EB4;
  --color-primary-fuscia: #6E3792;
}

.dark {
  --color-primary-10: #1e2a28;
  --color-primary-90: #f0fcfa;
  --color-error-10: #2b2021;
  --color-error-90: #fdf2f1;
  --color-success-10: #252e25;
  --color-success-90: #edf9ec;
  --color-warning-10: #2b2820;
  --color-warning-90: #fff7ed;
  --color-info-10: #0d1032;
  --color-info-90: #f4f7ff;
  --color-gray-0: #1b1b1c;
  --color-gray-10: #2a2c33;
  --color-gray-20: #3d3f4c;
  --color-gray-40: #898d9a;
  --color-gray-60: #a9abb2;
  --color-gray-80: #eaecef;
  --color-gray-90: #f7f8fa;
  --color-gray-100: #fff;
  --shadow-common: 2px 8px 44px #1d1d1d;

  --color-G-100: #F3F4F6;
  --color-G-200: #E5E7EB;
  --color-G-400: #9CA3AF;
  --color-G-500: #6B7280;
  --color-G-600: #4B5563;
  --color-G-900: #111827;
  --color-S-100: #F4ECFB;
  --color-S-600: #9950CD;
  --color-primary-fuscia: #6E3792;
}

body > iframe {
  display: none;
}

::-webkit-scrollbar {
  display: none;
}

.single-200-medium {
  @apply text-single-200;
  line-height: 20px;
  font-weight: 500;
}
