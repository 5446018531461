.active {
  @apply border-b-2;
  @apply border-primary-fuscia;
  @apply font-semibold;
  @apply text-primary-fuscia;
}

@media screen and (max-width: 768px) {
  .active {
    border-bottom-width: 1px;
  }
}
#title {
  font-family: Audiowide;
}
